<template>
  <div>
    <vue-headful
      title="Наши контакты"
      description="Контакты"
    />
    <div class="section section-main-100 cards pa-6">
      <v-card
        height="auto"
        class="box-1 elevation-0 d-flex justify-center align-center"
      >
        <div>
          <v-card-title class="pl-0 d-inline-block text-uppercase font-weight-bold text-h5">Свободные вакансии
          </v-card-title>
          <p>
            1. Специалист отдела продаж вторичного жилья. <br/>
            2. Специалист отдела продаж первичного жилья. <br/>
            3. Помощник риэлтора.
          </p>
          <v-card-title class="d-inline-block pa-0 font-weight-bold pb-3 pt-6">
            Предварительная запись на собеседование
          </v-card-title>
          <p>
            +7 (988) 257-44-45 - Мария Викторовна<br/>
            +7 (909) 407-85-81 - Евгений Александрович</p>
        </div>
      </v-card>
      <v-card
        height="auto"
        class="box-2 elevation-0 d-flex justify-center align-center"
      >
        <FindAgents/>
      </v-card>
      <v-card class="box-3 elevation-0 pl-3 pr-3">
        <!--        <v-card-title class="pl-0 d-inline-block text-uppercase font-weight-bold text-h5">Еще условия</v-card-title>
                <p>
                  1. Специалист отдела продаж вторичного жилья. <br/>
                  2. Специалист отдела продаж первичного жилья. <br/>
                  3. Помощник риэлтора.
                </p>
                <v-card-title class="body-1 d-inline-block pa-0 font-weight-bold pb-3 pt-6">
                  Предварительная запись на собеседование
                </v-card-title>
                <p class="body-1">
                  +7(988) 257-44-45 - Мария Викторовна<br/>
                  +7(909) 407-85-81 - Евгений Александрович</p>-->
      </v-card>
    </div>
  </div>
</template>

<script>
import FindAgents from '@/views/Main/FindAgents'

export default {
  name: 'Jobs',
  components: { FindAgents }
}
</script>

<style scoped>
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    row-gap: 42px;
  }
  .box-3 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media (max-width: 850px) {
    .cards {
      display: flex;
      flex-direction: column;
    }
    .box-1, .box-3 {
      text-align: center;
    }
  }
</style>
